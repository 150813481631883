<template>
  <v-app id="app" color="primary">
    <v-app-bar app color="primary" key="navbar">
      <span
        v-if="!$store.state.searching"
        class="headline font-weight-bold titleBasa ml-0 mr-2 white--text"
        @click="$router.push('/')"
      >
        <LogoBasa />
      </span>

      <span
        v-if="!$store.state.searching"
        class="headline font-weight-light titleBasa ml-0 mr-5 white--text d-none d-md-block"
        style="position:relative;top:8px;"
      >
        <span class="disfruta">
          Disfruta tu tiempo
        </span>
        Sin Pausa
      </span>

      <v-spacer />
      <v-btn
        class="mx-0"
        fab
        x-small
        depressed
        color="white"
        @click="handleSearching()"
        v-if="
          $store.state.searching == false && $store.state.visibleSearch == true
        "
      >
        <v-icon color="primary">
          fas fa-search
        </v-icon>
      </v-btn>

      <a
        :href="this.downloadLink"
        class="white--text mx-5"
        v-if="isMobile && !$store.state.searching"
      >
        <div id="store-logo">
          <div v-if="isMobile && isIOS">
            <AppStore />
          </div>
          <div v-else-if="isMobile && isAndroid">
            <PlayStore />
          </div>
        </div>
      </a>

      <transition
        enter-active-class="animated fadeInRight"
        :duration="{ enter: 300 }"
      >
        <v-container
          fluid
          class="pa-md-12 mx-auto"
          v-if="$store.state.searching"
        >
          <v-text-field
            v-if="$store.state.visibleSearch"
            type="search"
            v-model="search"
            label="Busca en el menu..."
            prepend-inner-icon="fas fa-search"
            solo
            clearable
            rounded
            flat
            hide-details
            dense
            autofocus
            clear-icon="far fa-times-circle"
            @click:clear="clearSearch()"
          ></v-text-field>
        </v-container>
      </transition>
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <div v-if="cartItemCount == 0">
          <v-icon medium color="white" class="mx-4">
            fas fa-shopping-cart
          </v-icon>
        </div>
        <div v-else :key="cartItemCount">
          <transition enter-active-class="animated rubberBand" appear>
            <v-badge
              color="grey darken-2"
              overlap
              :content="cartItemCount"
              class="mx-4"
            >
              <v-icon medium color="white" @click="checkOut()">
                fas fa-shopping-cart
              </v-icon>
            </v-badge>
          </transition>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import LogoBasa from "@/components/LogoBasa";
import AppStore from "@/components/AppStore";
import PlayStore from "@/components/PlayStore";
import { isMobile, isIOS, isAndroid } from "mobile-device-detect";

export default {
  name: "App",
  components: {
    LogoBasa,
    AppStore,
    PlayStore,
  },
  data() {
    return {
      isMobile: isMobile,
      isIOS: isIOS,
      isAndroid: isAndroid,
      downloadLink: "",
    };
  },
  created() {
    document.title = "Ocho | Menu";
  },
  mounted() {
    this.getDownloadLink();
  },
  methods: {
    getDownloadLink() {
      if (this.isMobile && this.isAndroid) {
        this.downloadLink =
          "https://play.google.com/store/apps/details?id=io.beanar.ocho";
      } else if (this.isMobile && this.isIOS) {
        this.downloadLink =
          "https://apps.apple.com/hn/app/ocho/id1471212294?l=en";
      } else {
        this.downloadLink = "";
      }
    },
    clearSearch() {
      this.$store.commit("setSearch", "");
      this.$store.commit("setSearching", false);
    },
    handleSearching() {
      this.$store.commit("setSearching", true);
    },
    checkOut() {
      this.$router.push({ name: "Checkout" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
  },
  computed: {
    search: {
      get: function() {
        return this.$store.state.search;
      },
      set: function(newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
body .v-application {
  font-family: "nexa" !important;
}
body .v-application {
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .headline,
  .title,
  .subtitle-1,
  .subtitle-2,
  .body-1,
  .body-2,
  .caption,
  .overline,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: "nexa" !important;
  }
}
.titleBasa svg {
  position: relative;
  height: 32px;
  top: 2px;
  display: inline-block;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #f0703d96;
  border-radius: 10px;
}
.main-container {
  width: 100%;
  justify-content: center;
}
.screen-width {
  width: 80% !important;
}
.disfruta {
  position: absolute;
  top: -18px;
  left: 0;
  font-size: 12.5px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .screen-width {
    width: 100% !important;
  }
}
#store-logo {
  width: 8em;
  padding-top: 5px;
  margin-right: -1.5rem;
}
@media only screen and (max-width: 600px) {
  #store-logo {
    width: 5em;
  }
}
</style>
