import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/business/:webName/:geoHash",
    name: "Business",
    component: () => import("../views/Business.vue"),
  },
  {
    path: "/business/:webName/:geoHash/products",
    name: "products",
    component: () => import("../views/Products.vue"),
  },
  {
    path: "/business/:webName/:geoHash/product",
    name: "Product",
    component: () => import("../views/Product.vue"),
  },
  {
    path: "/business/:webName/:geoHash/checkout",
    name: "Checkout",
    component: () => import("../views/Checkout.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
