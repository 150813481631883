var config = {
  firebase: {
    apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
    authDomain: "el-ocho.firebaseapp.com",
    databaseURL: "https://el-ocho.firebaseio.com",
    projectId: "el-ocho",
    storageBucket: "el-ocho.appspot.com",
    messagingSenderId: "770366666625",
    appId: "1:770366666625:android:fdff0851adf344b7",
  },
};

module.exports = config;
