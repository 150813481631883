<template>
  <div class="home primary">
    <Landing />
  </div>
</template>

<script>
import Landing from "@/components/Landing";

export default {
  name: "Home",
  components: { Landing },
  mounted() {
    if (this.$route.params.error) this.showAlert();
    this.$store.state.visibleSearch = false;
  },
  created() {
    document.title = "Ocho | Menu";
  },
  methods: {
    showAlert() {
      this.$swal({
        icon: "error",
        title: "Oops...",
        text: "No pudimos encontrar el negocio, por favor intenta de nuevo",
        timer: 5000,
        timerProgressBar: true,
      });
    },
  },
};
</script>
