import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    search: "",
    visibleSearch: true,
    businessStore: null,
    searching: false,
    productsStore: [],
    menuStore: [],
    addOnsStore: [],
    addOnsProductStore: [],
    cart: [],
  },
  mutations: {
    setBusiness(state, val) {
      state.businessStore = val;
    },
    setProducts(state, val) {
      state.productsStore = val;
    },
    setMenu(state, val) {
      state.menuStore = val;
    },
    setAddons(state, val) {
      state.addOnsStore = val;
    },
    setAddonProducts(state, payload) {
      if (
        !state.addOnsProductStore.find((product) => product.id == payload.id)
      ) {
        state.addOnsProductStore.push(payload);
      }
    },
    setSearch(state, target) {
      state.search = target;
    },
    setSearching(state, target) {
      state.searching = target;
    },
    addToCart(state, payload) {
      state.cart.push(payload);
    },
    clearCart(state) {
      state.cart = [];
    },
    updateCart(state, payload) {
      if (state.cart[payload.idx]) {
        Vue.set(state.cart, payload.idx, payload.productObj);
      }
    },
    removeFromCart: (state, payload) => {
      if (payload != -1) state.cart.splice(payload, 1);
    },
  },
  actions: {},
  getters: {
    cartTotal: (state) => {
      if (state.cart.length > 0) {
        return state.cart
          .map((product) => product.subTotalPrice)
          .reduce((total, amount) => total + amount);
      } else {
        return 0;
      }
    },
    cartItemCount: (state) => {
      return state.cart.length;
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      // Use sessionStorage.clear(); when user logs out manually.
    }),
  ],
});
