import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
const fb = require("@/firebase.js");
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueLazyload from "vue-lazyload";
import vuePositionSticky from "vue-position-sticky";
import VueTheMask from "vue-the-mask";

const sweetAlertOptions = {
  confirmButtonColor: "#F06A25",
};

const lazyLoadOptions = {
  preLoad: 1.3,
  lazyComponent: true,
  observer: true,
  observerOptions: {
    rootMargin: "0px",
    threshold: 0.1,
  },
};

Vue.config.productionTip = false;
Vue.use(fb);
Vue.use(VueSweetalert2, sweetAlertOptions);
Vue.use(VueLazyload, lazyLoadOptions);
Vue.use(vuePositionSticky);
Vue.use(VueTheMask);

new Vue({
  router,
  fb,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
